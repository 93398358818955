import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPages = [
    {
      title: 'Employees',
      url: '/employee-management/',
      icon: 'people'
    },
    {
      title: 'Payrolls',
      url: '/payroll-management',
      icon: 'cash'
    }
  ];

  constructor() {}
}
